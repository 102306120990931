import { CITY_LIST, KID_LIST, PORTFOLIO } from "../Actions/types";

const initialState = {
   kids: [],
   cities: [],
   portfolio: false
}


export default function (state = initialState, action) {
   const { type, payload } = action;
   switch (type) {
      case KID_LIST:
         return { ...state, kids: payload }
      case CITY_LIST:
         return { ...state, cities: payload }
      case PORTFOLIO:
         return { ...state, portfolio: payload }
      default:
         return state;
   }
}