// AUTH
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const OTP_SENT = "OTP_SENT";
export const USER_DATA = "USER_DATA";
export const LOGIN_MODAL = "LOGIN_MODAL";

// DASHBOARD
export const KID_LIST = "KID_LIST";
export const CITY_LIST = "CITY_LIST";
export const PORTFOLIO = "PORTFOLIO";

// CHAMPION
export const GLOBAL_KID_ID = "GLOBAL_KID_ID";
export const EVENT_DETAILS = "EVENT_DETAILS";
export const EVENT_AGE = "EVENT_AGE";
export const EXP_AGE = "EXP_AGE";
export const QUIZ_AGE = "QUIZ_AGE";
export const EVENTS_LIST = "EVENTS_LIST";
export const EXP_LIST = "EXP_LIST";
export const QUIZ_LIST = "QUIZ_LIST";
export const ACTIVITY_LIST = "ACTIVITY_LIST";
export const EVENT_KID_ID = "EVENT_KID_ID";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const ACTIVITY_DETAILS = "ACTIVITY_DETAILS";
export const SUBMISSION_LIST = "SUBMISSION_LIST";
export const COMPETITION_RULE = "COMPETITION_RULE";
export const REG_PERMISSION = "REG_PERMISSION";
export const PLAN_DATA = "PLAN_DATA";
export const MY_EVENTS = "MY_EVENTS";
export const EVENT_PROPS = "EVENT_PROPS";


// QUIZ
export const QUIZ_HISTORY = "QUIZ_HISTORY";
export const QUIZ_RESET = "QUIZ_RESET";
export const QUIZ_LOCAL_DATA = "QUIZ_LOCAL_DATA";
export const QUIZ_DATA = "QUIZ_DATA";
export const QUIZ_ACTIVITY = "QUIZ_ACTIVITY";
export const QUIZ_QUESTION = "QUIZ_QUESTION";
export const QUIZ_SUMMARY = "QUIZ_SUMMARY";
export const QUIZ_CERTIFICATE = "QUIZ_CERTIFICATE";
export const QUIZ_CHECK = "QUIZ_CHECK";
export const QUIZ_FORM_DATA = "QUIZ_FORM_DATA";

// DIGITAL PROFILE
export const DIGI_PROFILE = "DIGI_PROFILE";
export const DIGI_SUBMISSIONS = "DIGI_SUBMISSIONS";
export const DIGI_PUBLIC_SUBMISSION = "DIGI_PUBLIC_SUBMISSION";
export const DIGI_PIN_SUBMISSION = "DIGI_PIN_SUBMISSION";
export const DIGI_FEEDS = "DIGI_FEEDS"
export const DIGI_PUBLIC_FEEDS = "DIGI_PUBLIC_FEEDS";