import React from 'react'
import Slider from "react-slick";
import karthikeyan from "../../../Assets/images/karthikeyan.jpg"

function PrevArrow(props) {

  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`${className}`} >
      <img src='../../images/left-arrow-icon.png' />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`${className}`} >
      <img src='../../images/right-arrow-icon.png' />
    </div>
  );

}

const settings = {
  dots: false,
  infinite: false,
  autoplay: true,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }
  ],
};
const Testimonial = () => {
  return (
    <div className='container'>
      <div className='testimonial-carousel  animated-box'>
        {/* <Slider {...settings}> */}
        <div class="lead-tstmnl-slide montserrat">
          <div class="lead-tstmnl-img">
            <img src={karthikeyan} />
          </div>
          <div class="lead-tstmnl-text-cont">
            <h2 className='tstmnl-name'>Partnering for Excellence at IIM Sirmaur</h2>
            <p class="lead-tstmnl-comment">IIM Sirmaur's partnership with masterNAUKRI by KidEx has been transformative. Over 13 months, they have demonstrated unparalleled commitment, innovation, and ownership. From training for placements to AI-driven SaaS solutions, internship management, and EMBA digitization, their proactive approach has redefined excellence. Their ability to adapt, introduce fresh ideas, and exceed expectations is remarkable. This collaboration has significantly enhanced our placements, learning outcomes, and admissions. masterNAUKRI by KidEx is not just a partner but a true catalyst for growth and success. I will always be willing to selflessly recommend you to other meritorious organizations looking to make a difference.</p>
            <h6 className="mb-0"> Prof. Karthikeyan Balakumar</h6>
            <h6 className="fw-normal">Chairperson Placement Committee, IIM Sirmaur</h6>
          </div>
        </div>
        {/* </Slider> */}
      </div>
    </div>
  )
}

export default Testimonial;