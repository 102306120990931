import axios from "axios";
import { Loading, Notify } from "notiflix";
import { CITY_LIST, KID_LIST, PORTFOLIO } from "./types";
import { APIURL } from "./config";

export const getKidList = cb => async dispatch => {

  const config = {
    headers: {
      'ContentType': 'application/json',
    },
    data: {}
  }
  try {
    Loading.standard();
    const res = await axios.get(`${APIURL}/api/entity/kid/logged-in/fetch/list`, config);
    Loading.remove();
    if (res?.data?.status) {
      dispatch({
        type: KID_LIST,
        payload: res?.data?.body
      });
      if (cb && res?.data?.body?.length) cb(res?.data?.body?.[0]?.id);
    }

  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? "internal server error";
    Notify.failure(message);
  }
}

export const showPortfolio = id => async dispatch => {

  const config = {
    headers: {
      'ContentType': 'application/json',
    },
    data: {}
  }
  try {
    Loading.standard();
    const res = await axios.get(`${APIURL}/api/student/portfolio/whethershow?kidId=${id}`, config);
    Loading.remove();
    if (res?.data?.status) {
      dispatch({
        type: PORTFOLIO,
        payload: res?.data?.data
      });
    }

  } catch (err) {
    Loading.remove();
    const message = err?.response?.data?.message ?? "internal server error";
    Notify.failure(message);
  }
}

export const getCityList = () => async dispatch => {

  const config = {
    headers: {
      'ContentType': 'application/json',
    },
    data: {}
  };
  try {
    const res = await axios.get(`${APIURL}/api/city`, config);
    if (res?.data?.status) {
      dispatch({
        type: CITY_LIST,
        payload: res?.data?.data
      })
    } else if (res?.data?.message) {
      Notify.warning(res?.data?.message);
    } else {
      Notify.warning("something went wrong");
    }

  } catch (err) {
    console.log("err >>", err);
  }
}